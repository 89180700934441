import React from "react"
import { Col, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import Wistia from "../components/Wistia/Wistia"
import BlogReel from "../components/BlogReel/BlogReel"

const videoRowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    marginBottom: 64,
    marginTop: -100,
  },
}

const videoColProps = {
  span: 22,
}

const contentRowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const contentColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

const videoProps = {
  oEmbedUrl:
    "https://sundoghrv.wistia.com/medias/m55njegy9c?embedType=iframe&videoFoam=true&videoWidth=640",
}

export default ({ data }) => {
  const { hero } = data

  const meta = {
    seo: {
      title: "About | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
    },
    uri: "/about",
  }

  const heroProps = {
    contentfulAsset: hero,
    height: `35vh`,
  }

  return (
    <Shell>
      <Meta {...meta} />
      <Hero {...heroProps} />
      <Row {...videoRowProps}>
        <Col {...videoColProps}>
          <Wistia {...videoProps} />
        </Col>
      </Row>
      <Row {...contentRowProps}>
        <Col {...contentColProps}>
          <Typography.Title>The Lakeside Lodge Story</Typography.Title>
          <Typography.Paragraph>
            This is more than a lakefront condo with luxury amenities. Lakeside
            Lodge provides hassle free vacations and stress free property
            ownership experiences.
          </Typography.Paragraph>
          <Typography.Paragraph>
            Condos are sold to individuals who are free to use it as a vacation
            home whenever they want. And when they're not using their condo,
            they can place it in the hotel management rental program. The
            property management company functions as a traditional hotel
            operator and manages the on-site rental program for each individual
            condo owner.
          </Typography.Paragraph>
          <Typography.Paragraph>
            In turn, the property management company is responsible for costs
            associated with marketing, guest reservations, front desk services
            and housekeeping. Owners are responsible for condo furnishings,
            fixtures and equipment, utilities, real estate taxes, insurance and
            Condominium Association fees. The Condominium Association, through
            its management company, is responsible for the security and
            operating expenses of the amenities.
          </Typography.Paragraph>
        </Col>
      </Row>
      <BlogReel numPosts={3} />
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(contentful_id: { eq: "lfgrs9biZNI88bnGqrDLe" }) {
      description
      localFile {
        childImageSharp {
          fluid(jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
  }
`
